<template>
    <div class="my_home_page">
        <lookTeacher></lookTeacher>
    </div>
</template>
<script>
import lookTeacher from "@/views/educationalcenter/teachermanager/LookTeacher";
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {},
    components: {
        lookTeacher,
    },
};
</script>
<style lang="scss" scoped>
</style>